import { render, staticRenderFns } from "./login.vue?vue&type=template&id=5ca6a063&scoped=true&"
import script from "@/js/pages/login.js?vue&type=script&lang=js&"
export * from "@/js/pages/login.js?vue&type=script&lang=js&"
import style0 from "./login.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./login.vue?vue&type=style&index=1&id=5ca6a063&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca6a063",
  null
  
)

export default component.exports